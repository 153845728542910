import axios from 'axios'
import router from "@/router";
import {Message} from "element-ui";
import Cookies from "js-cookie";

// 创建可一个新的axios对象
const request = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,   // 后端的接口地址
    timeout: 30000
})

const LOGIN_PATH = "/web/";

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';

    let user = JSON.parse(localStorage.getItem("userInfo") || '{}')
    config.headers['token'] = user.token  // 设置请求头

    return config
}, error => {
    console.error('request error: ' + error) // for debug
    return new  Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        if (response.data != null && response.data.code === '401') {
            router.push('/403');
        }

        // 自定义请求判断
        if (response.data != null && response.data.code !== "200") {
            console.log(response.data);
            Message({ message: response.data.msg || '接口请求错误', type: 'error' });
        }

        return response.data;
    },
    error => {
        console.log(error);
        if (error.response && error.response.status !== 200) {
            Message({ message: '获取数据失败！', type: 'error' });
        }
        // 登录判断

        return error;
    }
);



export default request